import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { mixin as ClickAway } from 'vue-clickaway';
import UserMixin from '../../mixins/UserMixin';

/**
 * Home
 */
@Component({
    name: 'Header',
    components: {
    },
})
export default class Header extends mixins(ClickAway, UserMixin) {
    /**
     * Internal state
     */
    state: {[key: string]: any} = {
        dropdownClose: true,
    };

    /**
     * Click on the drawer button and emit the signal
     */
    toggleDrawer() {
        this.$emit('toggle-drawer');
    }

    /**
     * When clicking away
     */
    handleAway() {
        this.state.dropdownClose = true;
    }
}
