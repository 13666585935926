import { Vue, Component } from 'vue-property-decorator';
import Config from '../config';

/**
 * Home
 */
@Component({})
export default class PictureMixin extends Vue {
    /**
     * Return the full URL for a given picture
     */
    getPictureLocation(url: string) {
        const user = JSON.parse(localStorage.getItem('user') || '{}');
        const id = user._id || '';
        return `${Config.picture_url}/${id}/${url}`;
    }
}
