import { Vue, Component } from 'vue-property-decorator';

/**
 * Global entry point of the app
 */
@Component({
    components: {
    },
})
export default class App extends Vue {
    /**
     * Internal state of the component
     */
    state = {
    };
}
