import Crypto from 'crypto-js';
import Config from '../config';

/**
 * Return the headers needed to issue an API request
 * @param key The API key
 * @param secret The API secret
 * @param method The HTTP method
 * @param endpoint The API endpoint
 * @param The timestamp (UNIX ms)
 * @return The necessary headers
 */
export function getHeaders(key: string, secret: string,
                           method: string, endpoint: string,
                           timestamp: number): {[key: string]: any} {

    const concat = `${method}${endpoint}${timestamp}`;
    const signature = Crypto.HmacSHA1(concat.toLowerCase(), secret).toString(Crypto.enc.Hex);

    return {
        'X-ROCK-TIMESTAMP': timestamp,
        Authorization: `${key}:${signature}`,
        'Content-Type': 'application/json',
    };
}

/**
 * Generate JSON object for API calls
 * @param key The API key
 * @param secret The API secret
 * @param method The HTTP method
 * @param endpoint The API endpoint
 * @param body The data (if needed)
 * @return An object containing all the settings
 */
export function getAPISettings(key: string, secret: string,
                               method: string, endpoint: string,
                               body: { [key: string]: any } = {}): {[key: string]: any } {
    return {
        body,
        method: method.toUpperCase(),
        headers: getHeaders(key, secret, method, endpoint, new Date().getTime()),
        url: Config.api + endpoint,
    };
}

/**
 * Return the API key and secret from localStorage
 * @return An object containing the key and secret
 */
export function getKeyAndSecretFromLocalStorage() {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    const { key = '', secret = '' } = user;
    return { key, secret };
}
