import { Vue, Component, Prop } from 'vue-property-decorator';

/**
 * Home
 */
@Component({
    name: 'Backtop',
    components: {
    },
})
export default class Backtop extends Vue {
    /**
     * Internal state
     */
    state: {[key: string]: any} = {
    };
}
