import { Component, Prop, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import PictureMixin from '../../mixins/PictureMixin';

/**
 * Home
 */
@Component({
    name: 'Header',
    components: {
    },
})
export default class Header extends mixins(PictureMixin) {
    /**
     * State of the caroussel
     */
    @Prop({ type: Boolean, default: true }) readonly close!: boolean;

    /**
     * Pictures to display
     */
    @Prop({ type: Array, default: () => [] }) readonly pictures!: any[];

    /**
     * Active picture by default
     */
    @Prop({ type: Number, default: 0 }) readonly currentPosition!: number;
    /**
     * Internal state
     */
    state: {[key: string]: any} = {
        currentPicture: this.currentPosition >= 0 && this.pictures.length >= this.currentPosition ?
                            this.pictures[this.currentPosition] : null,
    };

    /**
     * Watcher over currentPosition
     */
    @Watch('currentPosition')
    onPositionChanged(pos: number) {
        this.go(pos);
    }

    /**
     * When clicking away
     */
    handleAway() {
        this.$emit('update:close', true);
    }

    /**
     * Swiping
     */
    swipeHandler (direction: string) {
        if (direction === 'right') {
            return this.go(this.currentPosition - 1);
        }

        if (direction === 'left') {
            return this.go(this.currentPosition + 1);
        }
    }

    /**
     * Move
     */
    go(position: number) {
        if (position < 0) {
            return;
        }

        if (position >= this.pictures.length) {
            return;
        }

        this.$set(this.state, 'currentPicture', this.pictures[position]);
        this.$emit('update:currentPosition', position);
    }
}
