import { Vue, Component } from 'vue-property-decorator';
import { ajax } from 'rxjs/ajax';
import * as APIUtils from '../utils/api';
import { mixins } from 'vue-class-component';
import UserMixin from './UserMixin';

/**
 * Home
 */
@Component({})
export default class extends mixins(UserMixin) {
    /**
     * Mounted hook
     */
    mounted() {
        const { key , secret } = APIUtils.getKeyAndSecretFromLocalStorage();
        const settings = APIUtils.getAPISettings(key, secret, 'POST', '/api/v1/artworks/read', {
            owner: this.user._id,
        });
        ajax(settings).subscribe((req: any) => {
            const { response = [] } = req;
            response.sort((a: any, b: any) =>
                b.date.replace('ca. ', '').localeCompare(a.date.replace('ca. ', '')));
            this.state.artworks = response;
            this.state.artworks.forEach((_: any, i: number) => {
                const iStr = `${i}`;
                this.$set(this.state.flippedCards, iStr, false);
            });
        });
    }
}
