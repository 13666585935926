import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { mixin as ClickAway } from 'vue-clickaway';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import { toArray, concatMap } from 'rxjs/operators';
import * as APIUtils from '../../utils/api';
import UserMixin from '../../mixins/UserMixin';

/**
 * Home
 */
@Component({
    name: 'Drawer',
    components: {
    },
})
export default class Drawer extends mixins(ClickAway, UserMixin) {
    /**
     * State of the drawer
     */
    @Prop({ type: Boolean, default: true }) readonly close!: boolean;

    /**
     * Internal state
     */
    state: {[key: string]: any} = {
        selects: [],
        artist: '__',
        medium: '__',
        year: '__',
    };

    /**
     * Search
     */
    search() {
        const { key , secret } = APIUtils.getKeyAndSecretFromLocalStorage();
        const body = {
            artist: this.state.artist === '__' ? undefined : this.state.artist,
            medium: this.state.medium === '__' ? undefined : this.state.medium,
            date: this.state.year === '__' ? undefined : this.state.year,
            owner: this.user._id,
        };

        const settings = APIUtils.getAPISettings(key, secret,
            'POST', '/api/v1/artworks/read', body);
        ajax(settings).subscribe((req: any) => {
            const { response = [] } = req;
            this.$emit('new-search', response);
            this.handleAway();
        });
    }

    /**
     * When clicking away
     */
    handleAway() {
        this.$emit('update:close', true);
    }

    /**
     * Mounted hook
     */
    mounted() {
        const { key , secret } = APIUtils.getKeyAndSecretFromLocalStorage();
        const types = ['artist', 'medium', 'date'].map((type: string) => {
            return APIUtils.getAPISettings(key, secret, 'GET', `/api/v1/witapp/select/${type}`);
        });

        of(...types).pipe(
            concatMap((settings: any) => {
                return ajax(settings);
            }),
            toArray()
        ).subscribe((results: any[]) => {
            console.log(results);
            const info = results.map((r: any) => {
                const { response = [] } = r;
                return response.filter((t: string) => t !== '');
            });

            this.state.selects = info;
        });
    }
}
