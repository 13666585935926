import Vue from 'vue';
import VueRouter from 'vue-router';
import App from './App.vue';
import './assets/styles/main.scss';
import { ROUTER as router } from './routes';
import Header from './components/header/Header.vue';
import Backtop from './components/backtop/Backop.vue';
import Switcher from './components/switcher/Switcher.vue';
import Drawer from './components/drawer/Drawer.vue';
import Modal from './components/modal/Modal.vue';
import Caroussel from './components/caroussel/Caroussel.vue';
import AudioPlayer from './components/player/AudioPlayer.vue';
import * as Utils from './utils/utils';
import Vue2TouchEvents from 'vue2-touch-events';

Vue.use(Vue2TouchEvents);
Vue.use(VueRouter);
// Register globally used utils
Object.defineProperty(Vue.prototype, '$utils', { value: Utils });
Vue.config.productionTip = false;

Vue.component('w-header', Header);
Vue.component('w-backtop', Backtop);
Vue.component('w-drawer', Drawer);
Vue.component('w-switcher', Switcher);
Vue.component('w-modal', Modal);
Vue.component('w-caroussel', Caroussel);
Vue.component('w-audio-player', AudioPlayer);

new Vue({
    router,
    render: h => h(App),
}).$mount('#app');
