/**
 * Transform a 1-D array to a matrix with row size = rowSize
 * @param input The 1-D array
 * @param rowSize The number of items per row
 * @return The matrix
 */
export function toMatrix(input: any[], rowSize: number = 10): any[] {
    return input.reduce((rows: any[], key: any, index: number) => {
        return (index % rowSize === 0 ? rows.push([key])
        : rows[rows.length - 1].push(key)) && rows;
    }, []);
}

/**
 * Strip slashes
 */
export function stripSlashes(str: string): string {
    return str.replace(/\\(.)/g, (match: any, char: string): any => {
        const d: {[key: string]: string} = {
            '"': '"', "'": "'", t: '\t', r: '\r', n: '\n', 0: '\0',
        };
        return d[char] || match;
    });
}

/**
 * Transform endline to <br>
 * @param The string to be transformed
 * @return The transformed string
 */
export function nl2br(value: string): string {
    return value.replace(/\n/g, '<br />');
}
