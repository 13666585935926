import { Vue, Component } from 'vue-property-decorator';

/**
 * Home
 */
@Component({})
export default class Header extends Vue {
    /**
     * Retrieve user from localStorage
     */
    get user() {
        return JSON.parse(localStorage.getItem('user') || '{}');
    }

    /**
     * Logout
     */
    logout() {
        localStorage.removeItem('user');
        this.$router.push({ path: '/login' });
    }
}
