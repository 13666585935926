import { Vue, Component } from 'vue-property-decorator';
import { ajax } from 'rxjs/ajax';
import Config from '../../config';

/**
 * Home
 */
@Component({
    name: 'Login',
    components: {
    },
})
export default class Login extends Vue {
    /**
     * Internal state
     */
    state: {[key: string]: any} = {
        password: '',
        login: '',
        success: null,
        validationErrors: [],
    };

    /**
     * Try to authenticate the user
     */
    login() {
        this.state.success = null;
        this.state.validationErrors = [];
        ajax({
            method: 'POST',
            async: true,
            url: `${Config.api}/api/v1/witapp/authenticate`,
            body: {
                login: this.state.login,
                password: this.state.password,
            },
        }).subscribe((req: any) => {
            const { response = {} } = req;
            if (response.success) {
                localStorage.setItem('user', JSON.stringify(response.user));
                this.state.success = true;
                this.$router.push({ path: '/' });
            } else {
                const { validationErrors = [] } = response;
                this.state.validationErrors = validationErrors;
                this.state.success = false;
            }
        });
    }

    /**
     * Check if an error pops in a field
     * @param field The field to check
     * @return True if there is an error for that field (false otherwise)
     */
    hasError(field: string): boolean {
        if (this.state.success === null) {
            return false;
        }

        return this.state.validationErrors.find((v: any) => v && v.field === field) !== undefined;
    }
}
