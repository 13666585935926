import { Component, Prop, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import PictureMixin from '../../mixins/PictureMixin';

/**
 * Home
 */
@Component({
    name: 'Modal',
    components: {
    },
})
export default class Modal extends mixins(PictureMixin) {
    /**
     * State of the modal
     */
    @Prop({ type: Boolean, default: true }) readonly close!: boolean;

    /**
     * Artwork
     */
    @Prop({ type: Object, required: true }) readonly artwork!: {[key: string]: any};

    /**
     * Artwork keys
     */
    @Prop({ type: Object, required: true }) readonly artworkKeys!: {[key: string]: any};

    /**
     * Internal state
     */
    state: {[key: string]: any} = {
    };

    /**
     * Get the main picture of the artwork
     */
    get mainPicture() {
        return this.artwork.pictures.find((p: any) => p.isMain) || {};
    }

    /**
     * Handle a click away
     */
    handleAway() {
        this.$emit('update:close', true);
    }

    /**
     * Watcher on the close property
     */
    @Watch('close')
    onCloseChanged(c: boolean) {
        console.log('close', c);
        if (!c) {
            document.body.classList.add('overflow-hidden');
        } else {
            document.body.classList.remove('overflow-hidden');
        }
    }

    /**
     * Mounted hook
     */
    mounted() {
        if (!this.close) {
            document.body.classList.add('overflow-hidden');
        } else {
            document.body.classList.remove('overflow-hidden');
        }
    }
}
