import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

const convertTimeHHMMSS = (val: number) => {
    const hhmmss = new Date(val * 1000).toISOString().substr(11, 8);

    return hhmmss.indexOf('00:') === 0 ? hhmmss.substr(3) : hhmmss;
};

/**
 * Home
 */
@Component({
    name: 'AudioPlayer',
    components: {
    },
})
export default class AudioPlayer extends Vue {
    /**
     * File to be played
     */
    @Prop({ type: String }) readonly file!: string;

    /**
     * Auto play feature
     */
    @Prop({ type: Boolean, default: false }) readonly autoPlay!: boolean;

    /**
     * Repeat
     */
    @Prop({ type: Boolean, default: false }) readonly loop!: boolean;

    /**
     * Show volume button and slider
     */
    @Prop({ type: Boolean, default: false }) readonly showVolume!: boolean;

    /**
     * Show mute button
     */
    @Prop({ type: Boolean, default: false }) readonly showMute!: boolean;

    /**
     * Show download button
     */
    @Prop({ type: Boolean, default: false }) readonly showDownload!: boolean;

    /**
     * Show repeat button
     */
    @Prop({ type: Boolean, default: false }) readonly showRepeat!: boolean;

    /**
     * Show duration slider
     */
    @Prop({ type: Boolean, default: true }) readonly showDuration!: boolean;

    /**
     * Show stop button
     */
    @Prop({ type: Boolean, default: true }) readonly showStop!: boolean;

    /**
     * Internal state
     */
    state: {[key: string]: any} = {
        audio: undefined,
        currentSeconds: 0,
        durationSeconds: 0,
        innerLoop: false,
        loaded: false,
        playing: false,
        previousVolume: 35,
        showVolume: false,
        volume: 100,
    };

    /**
     * Get the current time
     * @return The current time
     */
    get currentTime() {
        return convertTimeHHMMSS(this.state.currentSeconds);
    }

    /**
     * Get the duration time
     * @return The duration time
     */
    get durationTime() {
        return convertTimeHHMMSS(this.state.durationSeconds);
    }

    /**
     * Get the percentage of played music
     * @return The percentage
     */
    get percentComplete() {
        return this.state.currentSeconds / this.state.durationSeconds * 100;
    }

    /**
     * Check if muted or not
     * @return True if muted
     */
    get muted() {
        return this.state.volume / 100 === 0;
    }

    /**
     * Check if the audio is played or paused
     */
    @Watch('state.playing')
	onPlaying(value: boolean) {
        if (value) {
            return this.state.audio.play();
        }
        this.state.audio.pause();
    }

    /**
     * Trigger when volume changed
     */
    @Watch('state.volume')
	onVolumeChanged() {
        this.state.showVolume = false;
        this.state.audio.volume = this.state.volume / 100;
    }

    /**
     * Download the file
     */
    download() {
        this.stop();
        window.open(this.file, 'download');
    }

    /**
     * Load the audio
     */
    load() {
        if (this.state.audio.readyState >= 2) {
            this.state.loaded = true;
            this.state.durationSeconds = parseInt(this.state.audio.duration, 10);
            return this.state.playing = this.autoPlay;
        }

        throw new Error('Failed to load sound file.');
    }

    /**
     * Mute the sound
     */
    mute() {
        if (this.state.muted) {
            return this.state.volume = this.state.previousVolume;
        }

        this.state.previousVolume = this.state.volume;
        this.state.volume = 0;
    }

    /**
     * Seek into the song
     */
    seek(e: any) {
        if (!this.state.playing || e.target.tagName === 'SPAN') {
            return;
        }

        const el = e.target.getBoundingClientRect();
        const seekPos = (e.clientX - el.left) / el.width;

        this.state.audio.currentTime = this.state.audio.duration * seekPos;
    }

    /**
     * Stop the song
     */
    stop() {
        this.state.playing = false;
        this.state.audio.currentTime = 0;
    }

    /**
     * Update the current seconds
     */
    update() {
        this.state.currentSeconds = parseInt(this.state.audio.currentTime, 10);
    }

    /**
     * Created hook
     */
    created() {
        this.state.innerLoop = this.loop;
    }

    /**
     * Mounted hook
     */
    mounted() {
        this.state.audio = this.$el.querySelectorAll('audio')[0];
        this.state.audio.addEventListener('timeupdate', this.update.bind(this));
        this.state.audio.addEventListener('loadeddata', this.load.bind(this));
        this.state.audio.addEventListener('pause', () => { this.state.playing = false; });
        this.state.audio.addEventListener('play', () => { this.state.playing = true; });
    }
}
