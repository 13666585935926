import _ from 'lodash';
import Base from './base';
import Dev from './dev';
import Prod from './prod';
import PreProd from './preprod';

let actualConfig = Dev;

if (process.env.NODE_ENV === 'production') {
    actualConfig = Prod;
} else if (process.env.NODE_ENV === 'pre-production') {
    actualConfig = PreProd;
}

export default _.merge({}, Base, actualConfig);
