import Vue from 'vue';
import Router from 'vue-router';
import Mosaic from './pages/mosaic/Mosaic.vue';
import Cards from './pages/cards/Cards.vue';
import Login from './pages/login/Login.vue';
import * as APIUtils from './utils/api';
import { ajax } from 'rxjs/ajax';

/**
 * Route mapping
 */
export const MAPPING = {
    home: '/',
    mosaic: '/mosaic',
    login: '/login',
};

/**
 * Other routes
 */
export const OTHERS = [
    {
        path: MAPPING.home,
        components: {
            default: Cards,
        },
        meta: { requiresAuth: true, type: 'r', name: 'cards' },
        name: MAPPING.home.replace(/\//gi, '-'),
    },
    {
        path: MAPPING.mosaic,
        components: {
            default: Mosaic,
        },
        meta: { requiresAuth: true, type: 'r', name: 'mosaic' },
        name: MAPPING.mosaic.replace(/\//gi, '-'),
    },
    {
        path: MAPPING.login,
        components: {
            default: Login,
        },
        meta: { requiresAuth: false, access: '' },
        name: MAPPING.login.replace(/\//gi, '-'),
    }
];

/**
 * The router with all the routes
 */
export const ROUTER = new Router({
    // mode: 'history',
    routes: [...OTHERS],
});

ROUTER.beforeEach((to: any, from: any, next: any) => {
    if (to.matched.some((record: any) => record.meta.requiresAuth)) {
        const { key, secret } = APIUtils.getKeyAndSecretFromLocalStorage();
        const settings = APIUtils.getAPISettings(key, secret, 'GET',
            `/api/v1/witapp/access/${to.meta.name}/${to.meta.type}`);

        ajax(settings).subscribe((req: any) => {
            const { response = {} } = req;
            if (response.success) {
                next();
                return;
            }
            next({
                path: '/login',
            });
        }, (err: any) => {
            next({
                path: '/login',
            });
        });
    } else {
        next();
    }
});
