import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import PictureMixin from '../../mixins/PictureMixin';
import UserMixin from '../../mixins/UserMixin';
import ArtworkMixin from '../../mixins/ArtworkMixin';
import { ajax } from 'rxjs/ajax';
import * as APIUtils from '../../utils/api';

/**
 * Home
 */
@Component({
    name: 'Cards',
    components: {
    },
})
export default class Cards extends mixins(PictureMixin, UserMixin, ArtworkMixin) {
    /**
     * Internal state
     */
    state: {[key: string]: any} = {
        artworks: [],
        artworkKeys: {
            // "artist" :  "Artist",
            // "title" :  "Title",
            date :  'Date',
            period : 'Period',
            medium :  'Medium',
            technique :  'Technique',
            inventoryNumber:  'Inventory number',
            mentions :  'Special mentions',
            size :  'Dimensions',
            bibliography :  'Bibliography',
            provenances :  'Provenance(s)',
            exhibitions :  'Exhibition(s)',
            notes :  'Notes',
        },
        drawerClose: true,
        flippedCards: {

        },
        carousselClose: true,
        artworkPictures: [],
        carousselPosition: -1,
    };

    /**
     * Toggle the drawer
     */
    toggleDrawer() {
        this.state.drawerClose = !this.state.drawerClose;
    }

    /**
     * Check if the card is flipped
     */
    isFlipped(i: number) {
        return this.state.flippedCards[`${i}`];
    }

    /**
     * Flip a card
     */
    flip(i: number, _: any) {
        const iStr = `${i}`;
        this.$set(this.state.flippedCards, iStr, !this.state.flippedCards[iStr]);
    }

    /**
     * Show picture in caroussel
     */
    showPicture(artwork: any, i: number) {
        this.state.carousselPosition = i;
        this.state.artworkPictures = artwork.pictures;
        this.state.carousselClose = false;
    }

    /**
     * Handle a search through the drawer
     */
    onNewSearch(artworks: any[]) {
        this.state.artworks = artworks;
        this.state.carousselPosition = -1;
        this.state.artworkPictures = [];
        this.state.carousselClose = true;
    }
}
